<template>
    <div v-if="loggedInUser">
        <v-navigation-drawer
                v-model="drawer"
                :clipped="$vuetify.breakpoint.lgAndUp"
                app
                dark
                color="primary"
        >
            <v-container>
                <v-row xs12 justify="center" class="mb-2">
                    <router-link to="dashboard">
                      <v-img src="../assets/logo_logowanie.png" max-width="120"></v-img>
                    </router-link>
                </v-row>
                <v-divider />
                <v-row xs12 justify="center" class="mt-4 mb-4">
                    <AvatarDisplay/>
                </v-row>
                <v-row xs12 justify="center">
                    <span class="white--text">Witaj,</span>
                </v-row>
                <v-row xs12 justify="center" class="mb-4">
                    <span class="white--text"><strong>{{ loggedInUser.name }}</strong></span>
                </v-row>
                <v-divider />
            </v-container>
            <v-list dense>
                <template v-for="item in items">
                    <v-layout
                            v-if="item.heading"
                            :key="item.heading"
                            row
                            align-center
                    >
                        <v-flex xs6>
                            <v-subheader v-if="item.heading">
                                {{ item.heading }}
                            </v-subheader>
                        </v-flex>
                        <v-flex
                                xs6
                                class="text-xs-center"
                        >
                            <a
                                    href="#!"
                                    class="body-2 black--text"
                            >EDIT</a>
                        </v-flex>
                    </v-layout>
                    <v-list-group
                            v-else-if="item.children"
                            :key="item.text"
                            v-model="item.model"
                            :prepend-icon="item.model ? item.icon : item['icon-alt']"
                            append-icon=""
                    >
                        <template v-slot:activator>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item
                                v-for="(child, i) in item.children"
                                :key="i"
                                @click=""
                        >
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                            v-if="(item.userRole == 1 && userRole == 1)"
                            :key="item.text"
                            @click="$router.push(item.to)"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-else-if="(item.userRole == 11 && isSuperAdmin)"
                        :key="item.text"
                        @click="$router.push(item.to)"
                    >
                      <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.text }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                            v-else-if="(item.userRole == 0 && (userRole == 8 || userRole == 0 || (userRole == 5 && item.icon !== 'info' && item.icon !== 'videocam')))"
                            :key="item.text"
                            @click="$router.push(item.to)"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 2 && (userRole == 1 || userRole == 2))"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 3 && (userRole == 3 || (userRole == 5 && item.icon !== 'info' && item.icon !== 'videocam')))"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.text2 }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 6 && userRole == 6)"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 5 && userRole == 5)"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 7 && userRole == 7)"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 8 && (userRole == 8 || (userRole == 13 && isMBA)))"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-else-if="(item.userRole == 10)"
                      :key="item.text"
                      @click="$router.push(item.to)"
                  >
                    <v-list-item-action>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
                :clipped-left="$vuetify.breakpoint.lgAndUp"
                color="top"
                flat
        >
            <v-toolbar-title
                    style="width: 300px"
                    class="ml-0 pl-3"
            >
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <span class="hidden-sm-and-down">Unikadra</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="$router.push('/chat')">
              <v-badge
                  v-if="amountOfNotifForChat > 0"
                  :content=amountOfNotifForChat
                  color="red"
              >
                <v-icon>question_answer</v-icon>
              </v-badge>
              <v-icon v-else>
                question_answer</v-icon>
            </v-btn>

          <v-btn icon @click="$router.push('/notifications')">
            <v-badge
                v-if="amountOfNotifNotForChat > 0"
                :content=amountOfNotifNotForChat
                color="red"
            >
              <v-icon>notifications_none</v-icon>
            </v-badge>
            <v-icon v-else>notifications_none</v-icon>
          </v-btn>

            <v-btn icon @click="logout">
                <v-icon>exit_to_app</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <slot />
        </v-main>
<!--        <v-btn-->
<!--                bottom-->
<!--                color="primary"-->
<!--                dark-->
<!--                fab-->
<!--                fixed-->
<!--                right-->
<!--                @click="dialog = !dialog"-->
<!--        >-->
<!--            <v-icon>add</v-icon>-->
<!--        </v-btn>-->
        <v-dialog
                v-model="dialog"
                width="800px"
        >
            <v-card>
                <v-card-title class="grey darken-2">
                    Create contact
                </v-card-title>
                <v-container grid-list-sm>
                    <v-layout
                            row
                            wrap
                    >
                        <v-flex
                                xs12
                                align-center
                                justify-space-between
                        >
                            <v-layout align-center>
                                <v-avatar
                                        size="40px"
                                        class="mr-3"
                                >
                                    <img
                                            src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                                            alt=""
                                    >
                                </v-avatar>
                                <v-text-field
                                        placeholder="Name"
                                ></v-text-field>
                            </v-layout>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field
                                    prepend-icon="business"
                                    placeholder="Company"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs6>
                            <v-text-field
                                    placeholder="Job title"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    prepend-icon="mail"
                                    placeholder="Email"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    type="tel"
                                    prepend-icon="phone"
                                    placeholder="(000) 000 - 0000"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field
                                    prepend-icon="notes"
                                    placeholder="Notes"
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-actions>
                    <v-btn
                            text
                            color="primary"
                    >More</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            color="primary"
                            @click="dialog = false"
                    >Cancel</v-btn>
                    <v-btn
                            text
                            @click="dialog = false"
                    >Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AvatarDisplay from "../components/Avatar/AvatarDisplay";
import {UserRoleEnum} from '../enums';

export default {
    name: "Default",
    components: {AvatarDisplay},
    data: () => ({
      dialog: false,
      drawer: null,
      items: [
        { icon: 'directions_car', text: 'Rozliczenia przejazdów', to: '/trips', userRole: 0 },
        // {
        //   icon: 'keyboard_arrow_up',
        //   'icon-alt': 'keyboard_arrow_down',
        //   text: 'Więcej',
        //   model: false,
        //   children: [
        //     { text: 'Import' },
        //     { text: 'Export' },
        //     { text: 'Print' },
        //     { text: 'Undo changes' },
        //     { text: 'Other contacts' },
        //   ],
        // },
        { icon: 'calendar_today', text: 'Kalendarz zjazdów', to: '/calendar', userRole: UserRoleEnum.Professor },
        { icon: 'schedule', text: 'Czas pracy', to: '/times', userRole: UserRoleEnum.Professor },
        { icon: 'schedule', text: 'Czas pracy', to: '/times', userRole: UserRoleEnum.Zoom },
        { icon: 'settings', text: 'Ustawienia konta', to: '/settings', userRole: UserRoleEnum.Professor },
        { icon: 'people', text: 'Wykładowcy', to: '/users', userRole: UserRoleEnum.Admin },
        { icon: 'directions_car', text: 'Przejazdy', to: '/admin/trips', userRole: UserRoleEnum.Admin },
        { icon: 'calendar_today', text: 'Kalendarz zjazdów', to: '/admin/calendar', userRole: UserRoleEnum.Admin },
        { icon: 'schedule', text: 'Czas pracy', to: '/admin/times', userRole: UserRoleEnum.Admin },
        { icon: 'place', text: 'Lista placówek', to: '/places', userRole: UserRoleEnum.Admin },
        { icon: 'description', text: 'Raporty', to: '/reports', userRole: UserRoleEnum.Admin },
        { icon: 'settings', text: 'Ustawienia konta', to: '/admin-settings', userRole: UserRoleEnum.Admin },
        { icon: 'people_outline', text: 'Studenci', to: '/students', userRole: UserRoleEnum.Coordinator },
        // { icon: 'contacts', text: 'Kandydaci', to: '/candidates', userRole: UserRoleEnum.Coordinator },
        { icon: 'people_alt', text: 'Pracownicy administracyjni', to: '/administrativeStaff', userRole: UserRoleEnum.Admin },
        { icon: 'person_outline', text: 'Dane osobowe', text2: 'numer rachunku', to: '/student-personal-data', userRole: UserRoleEnum.Student },
        { icon: 'book', text: 'Dziennik', to: '/student-grades', userRole: UserRoleEnum.Student },
        { icon: 'question_answer', text: 'Komunikator', to: '/chat', userRole: UserRoleEnum.All },
        { icon: 'info', text: 'Dokumenty i informacje', to: '/student-general-information', userRole: UserRoleEnum.Student },
        { icon: 'info', text: 'Dokumenty i informacje', to: '/student-general-information', userRole: UserRoleEnum.Professor },
        { icon: 'info', text: 'Dokumenty i informacje', to: '/student-general-information', userRole: UserRoleEnum.Coordinator },
        { icon: 'info', text: 'Dokumenty i informacje', to: '/student-general-information', userRole: UserRoleEnum.ProfessorStudent },
        { icon: 'lock', text: 'Ustawienia hasła', to: '/password-change', userRole: UserRoleEnum.Student },
        { icon: 'contacts', text: 'Koordynatorzy, Ośrodki i Sekretariat', to: '/student-places', userRole: UserRoleEnum.Student },
        { icon: 'view_agenda', text: 'Kierunki', to: '/admin/courses', userRole: UserRoleEnum.Admin },
        { icon: 'folder', text: 'Pliki', to: '/admin/files', userRole: UserRoleEnum.Admin },
        { icon: 'euro', text: 'Wpłaty', to: '/accountant-settlements', userRole: UserRoleEnum.Accountant },
        { icon: 'euro', text: 'Wpłaty', to: '/accountant-settlements', userRole: UserRoleEnum.Admin },
        { icon: 'person_pin', text: 'Studenci (rozliczenia)', to: '/accountant-students', userRole: UserRoleEnum.Accountant },
        { icon: 'person_pin', text: 'Studenci (rozliczenia)', to: '/accountant-students', userRole: UserRoleEnum.Admin },
        // { icon: 'library_books', text: 'Dziennik', to: '/evaluations', userRole: UserRoleEnum.Professor },
        { icon: 'library_books', text: 'Dziennik (nowy)', to: '/evaluations-joined', userRole: UserRoleEnum.Professor },
        { icon: 'library_books', text: 'Dziennik (stary)', to: '/evaluations', userRole: UserRoleEnum.Admin },
        { icon: 'library_books', text: 'Dziennik (nowy)', to: '/evaluations-joined', userRole: UserRoleEnum.Admin },
        { icon: 'assignment', text: 'Protokoły', to: '/protocols', userRole: UserRoleEnum.Admin },
        { icon: 'videocam', text: 'Zoom', to: '/online-meetings', userRole: UserRoleEnum.Admin },
        { icon: 'videocam', text: 'Zoom', to: '/online-meetings', userRole: UserRoleEnum.Professor },
        { icon: 'videocam', text: 'Zoom', to: '/online-meetings', userRole: UserRoleEnum.ProfessorStudent },
        { icon: 'videocam', text: 'Zoom', to: '/online-meetings', userRole: UserRoleEnum.Zoom },
        { icon: 'face', text: 'Mentoring', to: '/mentors', userRole: UserRoleEnum.ProfessorMBA },
        { icon: 'bug_report', text: 'Zgłoś problem', to: '/issue-tracker', userRole: UserRoleEnum.Professor },
        { icon: 'bug_report', text: 'Zgłoś problem', to: '/issue-tracker', userRole: UserRoleEnum.Student },
        { icon: 'bug_report', text: 'Zgłoś problem', to: '/issue-tracker', userRole: UserRoleEnum.Coordinator },
        { icon: 'bug_report', text: 'Zgłoś problem', to: '/issue-tracker', userRole: UserRoleEnum.Accountant },
        { icon: 'bar_chart', text: 'Statystyki', to: '/statistics', userRole: UserRoleEnum.SuperAdmin },
      ]
    }),
    computed: {
      ...mapGetters({
        loggedInUser: 'users/getLoggedInUser',
        userRole: 'users/userRole',
        isSuperAdmin: 'users/isSuperAdmin',
        amountOfNotifForChat: 'notifications/getAmountOfNotificationsForChat',
        amountOfNotifNotForChat: 'notifications/getAmountOfNotificationsNotForChat',
        isMBA: 'users/isMBA',
      })
    },
    mounted() {
      // if (this.loggedInUser == '' || this.loggedInUser == null) {
      //   router.push('/');
      // }
    },
    methods: {
      ...mapActions({
        logout: 'users/logoutUser',
      })
    }
  }
</script>

