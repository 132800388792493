<template>
  <v-container>
    <v-dialog v-model="dialogRodo" :persistent="this.loggedInUser.rodo == null" max-width="800px">
      <v-card>
        <v-card-title>
          <v-container>
            <v-row>
              <span class="headline">Obowiązek informacyjny w związku z przetwarzaniem danych osobowych</span>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-4"></v-divider>
          <v-container>
            <v-row no-gutters>
              <v-col sm="12">
                1. Administratorami Pani/Pana danych osobowych są:<br />
                - Akademia Nauk Stosowanych Wincentego Pola w Lublinie (dalej: „Współadministrator”) z siedzibą: ul. Choiny 2, 20-816 Lublin,<br />
                - GTE Global Trade and Education sp. z o.o. (dalej: „Współadministrator”) z siedzibą: ul. Lachmana 2A lok. 4, 09-407 Płock (Sekretariat Studiów Podyplomowych).<br /><br />
                Z Administratorami można się kontaktować pisemnie, za pomocą poczty tradycyjnej na adresy:<br />
                - Akademia Nauk Stosowanych Wincentego Pola w Lublinie, ul. Choiny 2, 20-816 Lublin lub pod adresem mailowym: sekretariat@podyplomowestudia.eu,<br />
                - GTE Global Trade and Education sp. z o.o., ul. Lachmana 2A lok. 4, 09-407 Płock lub pod adresem mailowym: sekretariat@podyplomowestudia.eu.<br /><br />
                2. Współadministratorzy wyznaczyli inspektorów ochrony danych, z którym można się skontaktować pod adresem mailowym:<br />
                - Akademia Nauk Stosowanych Wincentego Pola w Lublinie: wtiwf@wssp.edu.pl, - GTE Global Trade and Education sp. z o.o.: iodo@rt-net.pl.<br /><br />
                3. Pani/Pana dane osobowe są przetwarzane na podstawie Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), tj. w oparciu o zgodę osoby, której dane dotyczą oraz ustawy z dnia 27 lipca 2005 r. Prawo o szkolnictwie wyższym.<br /><br />
                4. Przetwarzanie danych osobowych odbywa się w celu rekrutacji oraz udziału w wybranych studiach podyplomowych, kursach oraz przesyłania ofert kształcenia i doskonalenia.<br /><br />
                5. Przetwarzanie danych osobowych odbywa się na podstawie prawnie uzasadnionego interesu współadministratorów.<br /><br />
                6. Dane osobowe nie pochodzą od stron trzecich.<br /><br />
                7. Współadministratorzy nie zamierzają przekazywać danych do państwa trzeciego lub organizacji międzynarodowej.<br /><br />
                8. Współadministratorzy nie przewidują, że będą przekazywać dane osobowe, a jeżeli tak się stanie, to tylko na podstawie umowy powierzenia przetwarzania lub na podstawie przepisów prawa.<br /><br />
                9. Dane osobowe będą przetwarzane przez współadministratorów w przypadku:<br />
                - nie przyjęcia na studnia niezwłocznie po zakończeniu procesu rekrutacji,<br />
                - przyjęcia na zajęcia przez 10 lat od zakończenia studiów podyplomowych.<br /><br />
                10. Osoba, której dane dotyczą ma prawo do żądania od współadministratorów dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych.<br /><br />
                11. Skargę na działania Administratora można wnieść do Prezesa Urzędu Ochrony Danych Osobowych.<br /><br />
                12. Podanie danych osobowych jest wymogiem do wzięcia udziału w rekrutacji oraz studniach podyplomowych. Ich nie podanie spowoduje brak możliwości nawiązania współpracy.<br /><br />
                13. Współadministratorzy nie przewiduje zautomatyzowanego podejmowania decyzji.<br /><br />

                <b v-if="this.loggedInUser.rodo == null">Zgoda na przetwarzanie danych:</b><br />
                <v-checkbox v-if="this.loggedInUser.rodo == null" v-model="rodo" label="Wyrażam zgodę na przetwarzanie moich danych osobowych"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-divider class="mt-4"></v-divider>
        </v-card-text>
        <v-card-actions class="pa-7">
          <v-spacer></v-spacer>
          <v-btn v-if="this.loggedInUser.rodo == null" color="primary" :disabled="!rodo" rounded @click="saveRodo()">Akceptuję</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>Dane osobowe</v-card-title>
      <v-card-text>
        <p>Poniżej znajdują się Twoje dane z umowy. Jeśli dostrzegasz jakiś błąd, poinformuj nas o tym. Ze względów bezpieczeństwa, nie można edytować zmian.</p>

        <v-container>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="personal.name" label="Imię" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="address.street" label="Ulica" dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="personal.secondName" label="Drugie imię" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="address.houseNr" label="Numer budynku" dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="personal.surname" label="Nazwisko" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="address.homeNr" label="Numer lokalu" dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="personal.dayOfBirth" label="Data urodzenia" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="address.zip" label="Kod pocztowy" dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="address.city" label="Miasto" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field label="Województwo" dense disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="loggedInUser.telephone" label="Telefon" dense disabled></v-text-field>
            </v-col>
            <v-col sm="6">
              <v-text-field v-model="contact.email" dense disabled label="Email"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="studentCourse in loggedInUser.studentCourses" :key="studentCourse.name" cols="12" md="6" sm="12">
              <StudentCourseBankAccount :bank-account="studentCourse.bank_account ? studentCourse.bank_account : loggedInUser.bank_account" :text="studentCourse.name + ' - indywidualny rachunek bankowy'"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12">
              <div @click.stop="dialogRodo = true">
                <v-checkbox v-model="selected" disabled>
                  <template v-slot:label>
                    <span>Zaakceptowane przez Ciebie <span id="rodo">warunki korzystania i RODO</span></span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import student from "../../components/RegisterForm/InsuranceForm/Student.vue";
import StudentCourseBankAccount from "./StudentCourseBankAccount.vue";

export default {
  name: "StudentPersonalData",
  components: {StudentCourseBankAccount},
  data() {
    return {
      selected: true,
      dialogRodo: false,
      rodo: false
    }
  },
  computed: {
    student() {
      return student
    },
    ...mapGetters({
      loggedInUser: 'users/getLoggedInUser',
      userRole: 'users/userRole',
    }),
    personal() {
      return JSON.parse(this.loggedInUser.registration_form).personal
    },
    address() {
      return JSON.parse(this.loggedInUser.registration_form).address
    },
    contact() {
      return JSON.parse(this.loggedInUser.registration_form).contact
    }
  },
  mounted() {
    if (this.loggedInUser.rodo == null && (this.userRole == 3 || this.userRole == 5)) {
      this.dialogRodo = true
    }
    this.$store.dispatch('notifications/getNotifications');
  },
  methods: {
    ...mapActions({
      setUserRodo: 'users/setRodo'
    }),
    saveRodo() {
      this.setUserRodo().then(response => {
        if (response == 200) {
          this.dialogRodo = false;
        }
      }).catch(error => {
        this.$log.error('Saving new avatar error', error)
      })
    }
  }
}
</script>

<style scoped>
  #rodo {
    text-decoration: underline;
    cursor: pointer;
  }
  .v-input--is-disabled:not(.v-input--is-readonly) .v-icon.v-icon--disabled {
    pointer-events: auto;
  }
</style>
